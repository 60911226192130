import React from 'react';
import s from '../../locale/ourCoffee/s.json';
import coffeeImage from '../../images/optimized/coffee-beans.jpg';
import sachetsImage from '../../images/optimized/sachets.jpg';

import styles from './OurCoffee.module.scss';
import {Grid, Text, Page} from '../../components';

interface Props {
  data: any;
  t?: any;
}

type State = {};
type ComposedProps = Props;

export default class About extends React.PureComponent<ComposedProps, State> {
  render() {
    const t = this.props.t || (() => []);
    return (
      <Page title="Our Coffee">
        <Page.Layout>
          <div className={styles.PageBanner}>
            <div className={styles.PageBannerImageContainer}>
              <img className={styles.PageBannerImage} src={coffeeImage} />
            </div>
            <div className={styles.PageBannerContent}>
              <Grid>
                <Grid.ScreenWidth>
                  <Text
                    className={styles.PageBannerTitle}
                    tag="h1"
                    color="var(--color-heading-neg)"
                  >
                    {t(s.pageTitle)}
                  </Text>
                </Grid.ScreenWidth>
              </Grid>
            </div>
          </div>
          <Page.Section>
            <Grid>
              <Text>
                {t(s.pageText1)}
              </Text>
              <Text>
                {t(s.pageText2)}
              </Text>
              <div style={{height: '220px'}}>
                <img style={{objectFit: 'cover'}} src={sachetsImage} />
              </div>
            </Grid>
          </Page.Section>
        </Page.Layout>
      </Page>
    );
  }
}
